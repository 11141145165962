import React from 'react';
import { Box, AspectRatio, Heading, Text, Button, Center, Link } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import CountDown from '@components/Common/CountDown/V3';
import Image from '@components/Common/LazyImage';

import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { BG_COUNTING } from '@/constants/assets';

import * as FEATURE from '@/constants/feature-flags';

import txtWording from './locales';

function YoutubeLiveSection({...rest}) {
  return (
    <Box bgColor="bgAlternative" padding="32px" {...rest}>
      <Box bgColor="white" borderRadius="16px">
        {!FEATURE.ENABLE_LIVE_STREAMING && !FEATURE.ENABLE_VIDEO_PREWEDDING &&
          <Image src={BG_COUNTING} width="100%" maxWidth="500px" borderRadius="16px" />
        }
        {!FEATURE.ENABLE_LIVE_STREAMING && FEATURE.ENABLE_VIDEO_PREWEDDING &&
          <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '16px' }}
            />
          </AspectRatio>
        }
        {FEATURE.ENABLE_LIVE_STREAMING &&
          <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '16px' }}
            />
          </AspectRatio>
        }
        <Box padding="42px 16px 24px 16px">
          <WithAnimation>
            <Heading fontWeight="normal" textAlign="center" fontSize="5xl" color="mainColorText">
              {FEATURE.ENABLE_LIVE_STREAMING &&
                <span dangerouslySetInnerHTML={{ __html: txtWording.title[lang] }} />
              }
              {!FEATURE.ENABLE_LIVE_STREAMING &&
                <span dangerouslySetInnerHTML={{ __html: txtWording.countingTitle[lang] }} />
              }
            </Heading>
          </WithAnimation>
          <CountDown lang={[lang]} />
        </Box>
      </Box>
      {FEATURE.ENABLE_LIVE_STREAMING &&
        <Text textAlign="center" color="secondaryColorText" margin="32px 0 16px 0">
          <span dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
        </Text>
      }
      {FEATURE.ENABLE_LIVE_STREAMING &&
        <Center>
          <Link href={YOUTUBE_LINK} target="_blank">
            <Button
              size="sm"
              colorScheme="whiteAlpha"
              variant="solid"
              className="animation-heart"
              color="secondaryColorText"
              bgColor="mainColor"
              marginBottom="16px"
            >
              {txtWording.open[lang]}
            </Button>
          </Link>
        </Center>
      }
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
