import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

import useIsDesktopView from '@hooks/useIsDesktopView';

import txtWording from './locales';
import { string } from 'prop-types';

function ModalAddress({ onClose, visible, onCopyText, lang }) {
  const { isDesktopView } = useIsDesktopView();
  const address = `Jl. Jalan No. 123, Kota Bahagia`;
  const receiverName = `Bride & Groom`;

  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" color="mainColor" marginTop="0">
          {receiverName} <br />
        </Text>
        <Text textAlign="center" color="black">
          {address}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainColor"
            color="secondaryColorText"
            marginTop="16px"
            onClick={() => onCopyText(`${receiverName} - ${address}`, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth={isDesktopView ? '30%' : '100%'}>
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="heading"
          borderBottomWidth="1px"
          fontSize="2xl"
          fontWeight="normal"
          color="mainColorText"
        >
          {txtWording.sendGift[lang]}
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDescAddress[lang]}
          </Text>
          {renderBank()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAddress.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalAddress.defaultProps = {
  lang: 'id',
};

export default ModalAddress;
