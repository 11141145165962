// import P1 from '@/images/gallery/1.jpg';
// import P2 from '@/images/gallery/2.jpg';
// import P3 from '@/images/gallery/3.jpg';
// import P4 from '@/images/gallery/4.jpg';
// import P5 from '@/images/gallery/5.jpg';
// import P6 from '@/images/gallery/6.jpg';
// import P7 from '@/images/gallery/7.jpg';
// import P8 from '@/images/gallery/8.jpg';
// import P9 from '@/images/gallery/9.jpg';
// import P10 from '@/images/gallery/10.jpg';
// import P11 from '@/images/gallery/11.jpg';
// import P12 from '@/images/gallery/12.jpg';
// import P13 from '@/images/gallery/13.jpg';
// import P14 from '@/images/gallery/14.jpg';
// import P15 from '@/images/gallery/15.jpg';
// import P16 from '@/images/gallery/16.jpg';
// import P17 from '@/images/gallery/17.jpg';
// import P18 from '@/images/gallery/18.jpg';
// import P19 from '@/images/gallery/19.jpg';
// import P20 from '@/images/gallery/20.jpg';
// import P21 from '@/images/gallery/21.jpg';
// import P22 from '@/images/gallery/22.jpg';
// import P23 from '@/images/gallery/23.jpg';
// import P24 from '@/images/gallery/24.jpg';
// import P25 from '@/images/gallery/25.jpg';
// import P26 from '@/images/gallery/26.jpg';
// import P27 from '@/images/gallery/27.jpg';
// import P28 from '@/images/gallery/28.jpg';
// import P29 from '@/images/gallery/29.jpg';
// import P30 from '@/images/gallery/30.jpg';
// import P31 from '@/images/gallery/31.jpg';
// import P32 from '@/images/gallery/32.jpg';

export const photos = [
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM20.jpg?updatedAt=1698663622814`,
    // thumbnail: P1,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM24.jpg?updatedAt=1698663425430`,
    // thumbnail: P1,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM2.jpg?updatedAt=1698653468103`,
    // thumbnail: P2,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/M22.jpg?updatedAt=1698663248190`,
    // thumbnail: P2,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM.jpg?updatedAt=1698675724928`,
    // thumbnail: P3,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM4.jpg?updatedAt=1698653468733`,
    // thumbnail: P4,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM5.jpg?updatedAt=1698653468549`,
    // thumbnail: P5,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM6.jpg?updatedAt=1698653468568`,
    // thumbnail: P6,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM7.jpg?updatedAt=1698653467869`,
    // thumbnail: P7,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM9.jpg?updatedAt=1698653468552`,
    // thumbnail: P9,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM10.jpg?updatedAt=1698653468486`,
    // thumbnail: P10,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM11.jpg?updatedAt=1698653472408`,
    // thumbnail: P11,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM23.jpg?updatedAt=1698663347083`,
    // thumbnail: P11,
  },

  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM12.jpg?updatedAt=1698653472560`,
    // thumbnail: P12,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM13.jpg?updatedAt=1698653472390`,
    // thumbnail: P13,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM25.jpg?updatedAt=1698663556950`,
    // thumbnail: P13,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM14.jpg?updatedAt=1698653472502`,
    // thumbnail: P14,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM15.jpg?updatedAt=1698653473161`,
    // thumbnail: P15,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM16.jpg?updatedAt=1698653472781`,
    // thumbnail: P16,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM17.jpg?updatedAt=1698653473091`,
    // thumbnail: P17,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM18.jpg?updatedAt=1698653472979`,
    // thumbnail: P18,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM19.jpg?updatedAt=1698653472386`,
    // thumbnail: P19,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelmonica/revised%20gallery/MM21.jpg?updatedAt=1698663116144`,
    // thumbnail: P20,
  },
  // {
  //   original: P21,
  //   // thumbnail: P21,
  // },
  // {
  //   original: P22,
  //   // thumbnail: P22,
  // },
  // {
  //   original: P23,
  //   // thumbnail: P23,
  // },
  // {
  //   original: P24,
  //   // thumbnail: P24,
  // },
  // {
  //   original: P25,
  //   // thumbnail: P25,
  // },
  // {
  //   original: P26,
  //   // thumbnail: P26,
  // },
  // {
  //   original: P27,
  //   // thumbnail: P27,
  // },
  // {
  //   original: P28,
  //   // thumbnail: P28,
  // },
  // {
  //   original: P29,
  //   // thumbnail: P29,
  // },
  // {
  //   original: P30,
  //   // thumbnail: P30,
  // },
  // {
  //   original: P31,
  //   // thumbnail: P31,
  // },
  // {
  //   original: P32,
  //   // thumbnail: P32,
  // },
];
