import React from 'react';
import { bool, string } from 'prop-types';
import { BG_PRIMARY } from '@/constants/colors';
import { Box, Image } from '@chakra-ui/react';

import { FLOWER_BRIDE } from './BrideFlower';
import { FLOWER_GROOM } from './GroomFlower';

function ImageWithFlower({ isGroom, imgSrc, ...rest }) {
  if (isGroom) {
    return (
      <Box w="285px" overflow="hidden" justifyContent={'end'} display={'flex'} {...rest}>
        <Box width="100%" transform="translate(75px ,45px)" zIndex={1}>
          {FLOWER_GROOM}
        </Box>
        <Image
          h="265px"
          w="180px"
          borderRadius={'60% / 105px'}
          border={'1px'}
          borderColor={BG_PRIMARY}
          objectFit="cover"
          objectPosition="center"
          src={imgSrc}
          zIndex={2}
        />
      </Box>
    );
  }
  return (
    <Box w="285px" overflow="hidden" justifyContent={'start'} display={'flex'} {...rest}>
      <Image
        h="265px"
        w="180px"
        borderRadius={'60% / 105px'}
        border={'1px'}
        borderColor={BG_PRIMARY}
        objectFit="cover"
        objectPosition="center"
        src={imgSrc}
        zIndex={2}
      />
      <Box width="100%" transform="translate(-73px, 36px)" zIndex={1}>
        {FLOWER_BRIDE}
      </Box>
    </Box>
  );
}

ImageWithFlower.propTypes = {
  imgSrc: string,
  isGroom: bool,
};

ImageWithFlower.defaultProps = {
  imgSrc: '',
  isGroom: false,
};

export default React.memo(ImageWithFlower);
