import React from 'react';
import { bool, string } from 'prop-types';

import { Box, Center, Text, Link, Heading, Divider } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';
import Wave from '@components/Common/Wave';

import useInvitation from '@/hooks/useInvitation';

import Logo from '@/assets/icons/Monogram Pink.png';
import Invitato from '@/images/invitato.png';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_IG_INVITATO } from '@/constants';
import { BG_FOOTER } from '@/constants/assets';
import {
  SELECTED_VENDOR_COLLABORATION,
  ENABLE_COLLABORATION,
  DEFAULT_LANGUAGE as lang,
} from '@/constants/feature-flags';

import txtWording from './locales';

function CopyRightSection() {
  const invitation = useInvitation();

  return (
    <Box>
      <Box bgColor="bgPrimary" marginTop="-2px" padding="32px">
        <WithAnimation>
          <Text align="center" color="mainColorDarkText" padding="8px 0">
            {invitation ? (
              <>{txtWording.closingInvitation[lang]}</>
            ) : (
              <>{txtWording.closingAnnouncement[lang]}</>
            )}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Image src={BG_FOOTER} maxWidth="80%" borderRadius="16px" margin="32px 0" />
          </Center>
        </WithAnimation>
        <Heading color="mainColorDarkText" textAlign="center" fontWeight="normal" fontSize="3xl">
          With love,
        </Heading>
        <Center>
          <Image src={Logo} maxWidth="140px" />
        </Center>
        <Text textAlign="center" color="mainColorText" fontSize="22px" fontFamily="body">
          #MIKEmemorieswithMON
        </Text>
      </Box>
      <Box bgColor="bgAlternative">
        <Wave isElips transform="translateY(-4px)" minHeight="4.5rem" backgroundSize="cover" />
        <Box padding="16px 32px">
          <Center>
            <Link href={URL_IG_INVITATO} target="_blank">
              <Image src={Invitato} maxWidth="120px" />
            </Link>
          </Center>
          <Text color="secondaryColorText" fontSize="xs" textAlign="center">
            Created with Love by Invitato
          </Text>
          <Box>
            {/* Collaboration Vendor */}
            {ENABLE_COLLABORATION && (
              <Box margin="-4px 0 8px">
                <Center>
                  <Divider
                    margin="16px 0"
                    maxWidth="30%"
                    height="1px"
                    bgColor="secondaryColorText"
                    color="secondaryColorText"
                    border="none"
                    boxShadow="none"
                    borderBottomWidth="none"
                  />
                </Center>
                <Center>
                  <Link
                    href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                    target="_blank"
                  >
                    <Image
                      src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                      {...SELECTED_VENDOR_COLLABORATION.imgProps}
                    />
                  </Link>
                </Center>
                <Center>
                  <Text color="secondaryColorText" align="center" fontSize="xs">
                    In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                  </Text>
                </Center>
              </Box>
            )}
            {/* Copyright */}
            <Center marginTop={ENABLE_COLLABORATION ? '6px' : '12px'}>
              <Text textAlign="center" color="secondaryColorText" fontSize="xs">
                {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
              </Text>
            </Center>
            <Center>
              <Link
                textAlign="center"
                href={SOUND_URL}
                color="secondaryColorText"
                fontSize="xs"
                isExternal
              >
                {`Song by ${SOUND_BY}`}
              </Link>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CopyRightSection.propTypes = {
  fontSize: string,
  withFlatIcon: bool,
  withSong: bool,
};

CopyRightSection.defaultProps = {
  fontSize: 'sm',
  withFlatIcon: true,
  withSong: true,
};

export default React.memo(CopyRightSection);
