import React from 'react';
import { string, object } from 'prop-types';
import { Box, Center, Heading, Text } from '@chakra-ui/react';

import InstagramButton from '@components/Common/InstagramButton';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';
import ImageWithFlower from '@components/Common/ImageWithFlower';
import { USE_OLD_DETAILCARD_IMAGE } from '@/constants/feature-flags';
import { bool } from 'prop-types';

/**
 * Function to render CoupleDetailCard Info
 * @return {JSX.Element}
 */
function CoupleDetailCard(props) {
  const {
    name,
    fullName,
    parentInfo,
    instagramId,
    imgSrc,
    fullNameProps,
    parentInfoProps,
    instagramIdProps,
    imageProps,
    isGroom,
    ...rest
  } = props;
  return (
    <Box padding="32px">
      <Center>
        <WithAnimation>
          <Box padding="0 16px">
            <Heading
              fontSize="52px"
              color="mainColorText"
              marginBottom="-4px"
              fontWeight="normal"
              lineHeight="inherit"
            >
              {name}
            </Heading>
          </Box>
        </WithAnimation>
      </Center>
      <Box padding="0 16px 16px 0">
        <WithAnimation>
          <Text
            textAlign="center"
            color="mainColorDarkText"
            fontSize="12px"
            fontFamily="body"
            dangerouslySetInnerHTML={{ __html: fullName }}
            {...fullNameProps}
          />
        </WithAnimation>
        <WithAnimation>
          <Text
            textAlign="center"
            color="mainColorDarkText"
            marginTop="8px"
            fontSize="sm"
            {...parentInfoProps}
          >
            <span dangerouslySetInnerHTML={{ __html: parentInfo }} />
          </Text>
        </WithAnimation>
        {instagramId && (
          <Center p={2} marginTop="0">
            <WithAnimation top>
              <InstagramButton id={instagramId} {...instagramIdProps} />
            </WithAnimation>
          </Center>
        )}
      </Box>
      <WithAnimation {...rest}>
        <Center>
          {!USE_OLD_DETAILCARD_IMAGE ? (
            <ImageWithFlower isGroom={isGroom} imgSrc={imgSrc} {...imageProps} />
          ) : (
            <Image src={imgSrc} maxWidth="280px" {...imageProps} margin="8px 0" />
          )}
        </Center>
      </WithAnimation>
    </Box>
  );
}

CoupleDetailCard.propTypes = {
  fullName: string.isRequired,
  fullNameProps: object,
  imgSrc: string,
  imageProps: string,
  instagramId: string,
  instagramIdProps: object,
  name: string.isRequired,
  parentInfo: string.isRequired,
  parentInfoProps: object,
  isGroom: bool,
};

CoupleDetailCard.defaultProps = {
  instagramId: '',
  imgSrc: '',
  imageProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  parentInfoProps: {},
  isGroom: false,
};

export default CoupleDetailCard;
