import React from 'react';
import { Box } from '@chakra-ui/react';

import Wave from '@components/Common/Wave';
import Wishlist from './index';

import { BG_WISHLIST } from '@/constants/assets';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

function WishlistWrapper() {
  return (
    <Box background={`url(${BG_WISHLIST})`} bgSize="cover" bgPosition="center">
      <Box bgColor={BG_SECONDARY_TRANSPARENT}>
        <Wishlist inverse={false} />
        <Wave isWaveWishes backgroundSize="cover" minHeight="3rem" />
      </Box>
    </Box>
  );
}

export default WishlistWrapper;
