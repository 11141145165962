import React from 'react';
import Img from 'gatsby-image';
import { Box, useDisclosure } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';

import ImagesViewer from '@components/Common/ImagesViewer/Lazy';
import WithAnimation from '@components/Common/WithAnimation';
import RSVP from '@components/Section/RSVP';
import useLang from '@hooks/useLang';

import { BG_RSVP } from '@/constants/assets';
import { ENABLE_RSVP } from '@/constants/feature-flags';
import { BG_ALTERNATIVE, BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

import * as ID from '@/constants/identifier';

function CovidSection({...rest}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lang = useLang();

  const data = useStaticQuery(graphql`
    query {
      covid: file(relativePath: { eq: "covid.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      covidEn: file(relativePath: { eq: "covid-en.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box bgColor="bgPrimary" padding="32px 16px" {...rest}>
      <Box height="200px" />
      <Box
        bgColor="mainColor"
        marginBottom="-40px"
        borderRadius="16px"
        bgImage={`url(${BG_RSVP})`}
        bgSize="cover"
        bgPosition="center"
      >
        <Box bgColor={BG_SECONDARY_TRANSPARENT} padding="16px 32px" borderRadius="16px">
          <WithAnimation>
            <Box
              boxShadow="lg"
              borderRadius="16px"
              onClick={onOpen}
              backgroundColor="bgAlternative"
              transform="translateY(-200px)"
              border={`4px solid ${BG_ALTERNATIVE}`}
            >
              <Img
                fluid={lang === 'en' ? data.covidEn.childImageSharp.fluid : data.covid.childImageSharp.fluid}
                imgStyle={{ borderRadius: '16px' }}
                style={{ backgroundColor: 'transparent' }}
              />
            </Box>
          </WithAnimation>
          {ENABLE_RSVP && (
            <Box marginTop="-160px">
              <RSVP lang={lang} name={ID.ID_RSVP_SECTION} />
            </Box>
          )}
        </Box>
      </Box>
      <Box height="42px" />
      {isOpen && (
        <ImagesViewer 
          images={[
            lang === 'en' 
              ? data.covidEn.childImageSharp.fluid.src
              : data.covid.childImageSharp.fluid.src
          ]}onClose={onClose}
        />
      )}
    </Box>
  );
}

export default React.memo(CovidSection);
