import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { BsFillEnvelopeOpenFill } from 'react-icons/bs';
import { Button, Center } from '@chakra-ui/react';

/**
 * function to render button scroll down
 * @returns {JSX.Element}
 */
function ScrollToDown({ loading, onClick, text, withIcon, style }) {
  return (
    <Center style={style}>
      <Button
        borderRadius="16px"
        leftIcon={withIcon ? <BsFillEnvelopeOpenFill /> : null}
        colorScheme="blackAlpha"
        size="sm"
        isLoading={loading}
        onClick={onClick}
        className="animation-heart"
        fontWeight="normal"
        backgroundColor="mainColor"
        textColor="secondaryColorText"
      >
        {text}
      </Button>
    </Center>
  );
}

ScrollToDown.propTypes = {
  loading: bool.isRequired,
  text: string,
  style: object,
  withIcon: bool,
  onClick: func.isRequired,
};

ScrollToDown.defaultProps = {
  text: 'Buka Undangan',
  withIcon: true,
  style: {},
};

export default React.memo(ScrollToDown);
