import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import DetailCard from './DetailCard';

import useInvitation from '@/hooks/useInvitation';
import useGuestName from '@/hooks/useGuestName';

import {
  BOY_NAME,
  BOY_PARENT_NAME,
  GIRL_NAME,
  GIRL_PARENT_NAME,
  BOY_NAME_SHORT,
  GIRL_NAME_SHORT,
  IG_BOY,
  IG_GIRL,
  IS_BOY_FIRST,
} from '@/constants';

import { IMG_GIRL, IMG_MAN } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang, MUSLIM_INVITATION } from '@/constants/feature-flags';

import txtWording from './locales';

const COMMON_OPTIONS = {
  fullNameProps: { fontSize: '2xl', padding: '8px 0 4px 0' },
  instagramButton: { onlyIcon: true, backgroundColor: 'mainColorText', size: 'sm' },
};

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();
  const guestName = useGuestName();

  return (
    <Box bgColor="bgPrimary" padding="0 16px" {...rest}>
      <WithAnimation>
        <Text align="center" color="mainColorDarkText">
          {txtWording.dear[lang]}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Text align="center" color="mainColorDarkText" marginBottom="12px">
          {guestName || txtWording.guest[lang]}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Text align="center" fontSize="md" padding="0 16px 24px 16px" color="mainColorDarkText">
          {isInvitation
            ? MUSLIM_INVITATION
              ? txtWording.openingInvitationMuslim[lang]
              : txtWording.openingInvitationGeneral[lang]
            : MUSLIM_INVITATION
            ? txtWording.openingAnnuncementMuslim[lang]
            : txtWording.openingAnnuncementGeneral[lang]}
        </Text>
      </WithAnimation>
      {/* BOX with Invert Colors */}
      {IS_BOY_FIRST ? (
        <>
          <Box padding="0px">
            <DetailCard
              isGroom
              align="right"
              imgSrc={IMG_MAN}
              name={BOY_NAME_SHORT}
              fullName={BOY_NAME}
              fullNameProps={COMMON_OPTIONS.fullNameProps}
              parentInfo={BOY_PARENT_NAME}
              instagramId={IG_BOY}
              instagramIdProps={COMMON_OPTIONS.instagramButton}
            />
          </Box>
          <Box padding="0px">
            <DetailCard
              name={GIRL_NAME_SHORT}
              fullName={GIRL_NAME}
              fullNameProps={{ ...COMMON_OPTIONS.fullNameProps }}
              parentInfo={GIRL_PARENT_NAME}
              instagramId={IG_GIRL}
              imgSrc={IMG_GIRL}
              instagramIdProps={COMMON_OPTIONS.instagramButton}
            />
          </Box>
        </>
      ) : (
        <>
          <Box padding="0px">
            <DetailCard
              align="right"
              imgSrc={IMG_GIRL}
              name={GIRL_NAME_SHORT}
              fullName={GIRL_NAME}
              fullNameProps={COMMON_OPTIONS.fullNameProps}
              parentInfo={GIRL_PARENT_NAME}
              instagramId={IG_GIRL}
              instagramIdProps={COMMON_OPTIONS.instagramButton}
            />
          </Box>
          <Box padding="0px">
            <DetailCard
              isGroom
              name={BOY_NAME_SHORT}
              fullName={BOY_NAME}
              fullNameProps={{ ...COMMON_OPTIONS.fullNameProps }}
              parentInfo={BOY_PARENT_NAME}
              instagramId={IG_BOY}
              imgSrc={IMG_MAN}
              instagramIdProps={COMMON_OPTIONS.instagramButton}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default React.memo(CoupleInfo);
