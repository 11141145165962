import React from 'react';
import { bool } from 'prop-types';
import { BG_SECONDARY, BG_PRIMARY } from '@/constants/colors';
import { Box } from '@chakra-ui/react';

function Wave({ isElips, isWaveWelcome, isWaveWishes, ...rest }) {
  let waveEncoded = (
    <svg
      width="100%"
      height="40px"
      viewBox="0 0 1280 140"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={BG_SECONDARY}>
        <path
          d="M725.29 101.2C325.22 122.48 0 0 0 0v140h1280V0s-154.64 79.92-554.71 101.2z"
          fillOpacity=".3"
        />
        <path
          d="M556.45 119.74C953.41 140 1280 14 1280 14v126H0V0s159.5 99.48 556.45 119.74z"
          fillOpacity=".5"
        />
        <path d="M640 140c353.46 0 640-140 640-139v140H0V0s286.54 140 640 140z" />
      </g>
    </svg>
  );

  if (isElips) {
    waveEncoded = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill={BG_PRIMARY}
          fillOpacity="1"
          d="M0,32L120,69.3C240,107,480,181,720,186.7C960,192,1200,128,1320,96L1440,64L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
        />
      </svg>
    );
  } else if (isWaveWelcome) {
    waveEncoded = (
      <svg
        width="100%"
        height="40px"
        viewBox="0 0 1280 140"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={BG_PRIMARY}>
          <path
            d="M725.29 101.2C325.22 122.48 0 0 0 0v140h1280V0s-154.64 79.92-554.71 101.2z"
            fillOpacity=".3"
          />
          <path
            d="M556.45 119.74C953.41 140 1280 14 1280 14v126H0V0s159.5 99.48 556.45 119.74z"
            fillOpacity=".5"
          />
          <path d="M640 140c353.46 0 640-140 640-139v140H0V0s286.54 140 640 140z" />
        </g>
      </svg>
    );
  } else if (isWaveWishes) {
    waveEncoded = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="49px"
        viewBox="0 0 1280 140"
        preserveAspectRatio="none"
      >
        <g fill={BG_PRIMARY}>
          <path
            d="M1280 3.4C1050.59 18 1019.4 84.89 734.42 84.89c-320 0-320-84.3-640-84.3C59.4.59 28.2 1.6 0 3.4V140h1280z"
            fillOpacity=".3"
          />
          <path
            d="M0 24.31c43.46-5.69 94.56-9.25 158.42-9.25 320 0 320 89.24 640 89.24 256.13 0 307.28-57.16 481.58-80V140H0z"
            fillOpacity=".5"
          />
          <path d="M1280 51.76c-201 12.49-242.43 53.4-513.58 53.4-320 0-320-57-640-57-48.85.01-90.21 1.35-126.42 3.6V140h1280z" />
        </g>
      </svg>
    );
  }

  return (
    <Box width="100%" {...rest}>
      {waveEncoded}
    </Box>
  );
}

Wave.propTypes = {
  isElips: bool,
  isWaveWelcome: bool,
  isWaveWishes: bool,
};

Wave.defaultProps = {
  isElips: false,
  isWaveWelcome: false,
  isWaveWishes: false,
};

export default React.memo(Wave);
