import React from 'react';
import { bool, func } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import loadable from '@loadable/component';

import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Text,
  Image,
} from '@chakra-ui/react';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import ImgLogo from '@/assets/icons/Monogram Pink.png';
import useInvitation from '@/hooks/useInvitation';

import { photos as photosData } from './api/photo-data';
import { COVER_GALLERY_3 } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';
import Wave from '@components/Common/Wave';

function PhotoContent({ isOpen, onClose }) {
  const isInvitation = useInvitation();

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" />
        <DrawerBody bgColor="bgPrimary" width="100%" padding="0">
          <Box
            bgImage={`url(${COVER_GALLERY_3})`}
            height="450px"
            bgSize="cover"
            bgPosition="center 20%"
          />
          <Wave isWaveWishes width="100%" height="40px" transform="translateY(-38px)" />
          <Box height="38px" marginTop="-60px" />
          {/* Images Cover */}
          <Box padding="32px" paddingTop="32px" textAlign="center" color="mainColorText">
            <Text fontSize="xl">
              “ True Love Stories Never Have Endings”
              <br />- Richard Bach
            </Text>
            {/* Jakarta Section */}
            <Box paddingTop="28px">
              <Box marginTop="24px">{isOpen && <LoadableGallerry data={photosData} />}</Box>
            </Box>
          </Box>
          <Center>
            <Image src={ImgLogo} maxW="100px" marginTop="16px" />
          </Center>
          <Center padding="16px 0 48px 0">
            <Button
              leftIcon={<BiArrowBack />}
              size="sm"
              fontWeight="light"
              color="mainColor"
              variant="outline"
              colorScheme="blackAlpha"
              onClick={() => onClose()}
            >
              {isInvitation ? txtWording.backInvitation[lang] : txtWording.backAnnouncement[lang]}
            </Button>
          </Center>
          <Box height="50px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
