import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
} from '@chakra-ui/react';

import useIsDesktopView from '@hooks/useIsDesktopView';
import Image from '@components/Common/LazyImage';
import QRBank from '@/images/bca.jpg';

import BANK from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const { isDesktopView } = useIsDesktopView();

  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text textAlign="center" color="mainColor" marginTop="0">
          {bankNumber}
        </Text>
        <Text textAlign="center" color="black">
          {userName}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainColor"
            color="secondaryColorText"
            marginTop="16px"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth={isDesktopView ? '30%' : '100%'}>
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="heading"
          borderBottomWidth="1px"
          fontSize="2xl"
          fontWeight="normal"
          color="mainColorText"
        >
          Bank Transfer
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDesc[lang]}
          </Text>
          {false && (
            <Center>
              <Image
                src={QRBank}
                borderRadius="16px"
                marginTop="8px"
                maxWidth="200px"
                boxShadow="md"
                padding="8px"
              />
            </Center>
          )}
          {renderBank(BANK.bca, 'Michael Christophorus Yoshua', '6370318652')}
          {renderBank(BANK.bca, 'Monica Handra', '6042025182')}
        </DrawerBody>
        {false && (
          <DrawerFooter>
            <Button colorScheme="orange" fontWeight="normal" size="sm" mr={3} onClick={onClose}>
              Tutup
            </Button>
            {false && (
              <Button colorScheme="orange" size="sm" onClick={() => onCopyText('8648158740')}>
                Salin Nomor Rekening
              </Button>
            )}
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;
