import React from 'react';
import { func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  Text,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  Center,
  useToast,
} from '@chakra-ui/react';

import useFormFields from '@/hooks/useFormFields';
import useMutation from '@/hooks/useMutation';
import useInvitation from '@/hooks/useInvitation';
import getTimeNow from '@invitato/helpers/dist/getTimeNow';

import { API_HOSTNAME, SHEET_GIFT_CONFIRMATION } from '@/constants';
import { BG_FOOTER } from '@/constants/assets';
import { ENABLE_BANK_TRANSFER, ENABLE_SEND_GIFT } from '@/constants/feature-flags';

import txtWording from './locales';

const INPUT_COMMON_PROPS = {
  borderRadius: 'md',
  isRequired: true,
  size: 'sm',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgAlternative',
  color: 'secondaryColorText',
  _placeholder: { color: 'secondaryColorText' },
  _focus: { borderColor: 'mainColorText' },
};

function Confirmation({ lang, onBack }) {
  const [loading, setLoading] = React.useState(false);
  const [isBankTransfer, setIsBankTransfer] = React.useState(false);
  const [isGift, setIsGift] = React.useState(false);
  const toast = useToast();
  const isInvitation = useInvitation();

  const [onConfirm] = useMutation(
    API_HOSTNAME,
    SHEET_GIFT_CONFIRMATION,
    'insert_gift_confirmation',
  );
  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: '',
    isBankTransfer: false,
    isGift: false,
    note: '',
    hp: '',
  });

  const onSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    // set loading state
    setLoading(true);

    const getYesNo = (p) => (p === true ? 'YA' : 'TIDAK');
    const query = `nama=${formFields.name}&hp=${formFields.hp}&catatan=${
      formFields.note
    }&bank_transfer=${getYesNo(isBankTransfer)}&hadiah_fisik=${getYesNo(
      isGift,
    )}&waktu=${getTimeNow()}`;

    const result = await onConfirm(query);

    if (result.success) {
      toast({
        title: txtWording.submitted[lang],
        description: txtWording.successSent[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onResetForm();
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedSent[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  return (
    <Box
      bgImage={`url(${BG_FOOTER})`}
      bgSize="cover"
      minHeight="100vh"
      bgPosition="center"
      padding="32px"
    >
      <Box bgColor="white" padding="24px 16px" borderRadius="16px" boxShadow="lg">
        <Heading color="mainColorText" fontWeight="normal" fontSize="4xl" textAlign="center">
          {txtWording.title[lang]}
        </Heading>
        <Text color="bgAlternative" textAlign="center" fontSize="sm" padding="16px 0">
          {txtWording.desc[lang]}
        </Text>
        <Box>
          <FormControl margin="8px 0">
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.name[lang]}
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <FormControl margin="8px 0">
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.hp[lang]}
              value={formFields.hp}
              onChange={createChangeHandler('hp')}
            />
          </FormControl>
          <FormControl margin="24px 0">
            <FormLabel color="bgAlternative" margin="8px 0" fontSize="sm">
              {txtWording.giftType[lang]}
            </FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                >
                  <Text fontSize="sm" color="bgAlternative">{txtWording.bank[lang]}</Text>
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox
                  value={isGift}
                  onChange={() => setIsGift(!isGift)}
                >
                  <Text fontSize="sm" color="bgAlternative">{txtWording.gift[lang]}</Text>
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="8px 0">
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              placeholder={txtWording.notes[lang]}
              onChange={createChangeHandler('note')}
            />
            {false && (
              <FormLabel fontSize="xs" color="bgAlternative" margin="8px 0">
                {txtWording.additionalNotes[lang]}
              </FormLabel>
            )}
          </FormControl>
        </Box>
        <Box marginTop="24px">
          <Center>
            <Button
              isLoading={loading}
              fontWeight="normal"
              size="sm"
              colorScheme="blackAlpha"
              bgColor="bgAlternative"
              color="secondaryColorText"
              className="animation-heart"
              onClick={onSendConfirmation}
            >
              {txtWording.sendConfirm[lang]}
            </Button>
          </Center>
        </Box>
      </Box>
      <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            colorScheme="whiteAlpha"
            fontWeight="normal"
            bgColor="mainColor"
            color="secondaryColorText"
            onClick={onBack}
          >
            {isInvitation
              ? <>{txtWording.backToHome[lang]}</>
              : <>{txtWording.backToAnnoncement[lang]}</>
            }
          </Button>
        </Center>
      </Box>
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: 'id',
};

export default Confirmation;
