import React, { useState } from 'react';
import { string } from 'prop-types';

import { Box, Text, Heading, Center, Button, useToast } from '@chakra-ui/react';
import { GiWallet } from 'react-icons/gi';
import { FaHome, FaCheckCircle } from 'react-icons/fa';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';

import { BG_GIFTCARD } from '@/constants/assets';
import { ENABLE_BANK_TRANSFER, ENABLE_SEND_GIFT, ENABLE_GIFT_CONFIRMATION } from '@/constants/feature-flags';

import txtWording from './locales';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  confirmation: 'confirmation',
  idle: '',
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ lang, ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box>
      <Image src={BG_GIFTCARD} width="100%" maxWidth="500px" {...rest} />
      <Box bgColor="bgPrimary" padding="32px">
        <Box padding="32px" bgColor="white" borderRadius="16px">
          <Center>
            <WithAnimation>
              <Heading size="xl" textAlign="center" color="mainColorText" fontWeight="normal">
                {txtWording.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text color="black" align="center" margin="32px 0">
              {txtWording.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  leftIcon={<GiWallet style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  bgColor="mainColor"
                  color="secondaryColorText"
                  onClick={() => setModalState(MODAL_STATE.bank)}
                >
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_SEND_GIFT && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  colorScheme="blackAlpha"
                  leftIcon={<FaHome style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  bgColor="mainColor"
                  color="secondaryColorText"
                  onClick={() => setModalState(MODAL_STATE.address)}
                >
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button
                  size="sm"
                  className="animation-heart"
                  colorScheme="blackAlpha"
                  variant="outline"
                  leftIcon={<FaCheckCircle style={{ marginRight: '-4px' }} />}
                  fontWeight="normal"
                  color="mainColor"
                  onClick={() => setModalState(MODAL_STATE.confirmation)}
                >
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
      </Box>
      <ModalBank
        lang={lang}
        visible={modalState === MODAL_STATE.bank}
        onClose={onClose}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        visible={modalState === MODAL_STATE.address}
        onClose={onClose}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        visible={modalState === MODAL_STATE.confirmation}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: 'id',
};

export default React.memo(GiftCardSection);
