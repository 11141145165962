import React from 'react';
import { Box } from '@chakra-ui/react';

import { SLIDE_1, SLIDE_2, SLIDE_3, SLIDE_4, SLIDE_5 } from '@/constants/assets';

import './styles.css';
import Wave from '../Wave';

function ImagesFade() {
  return (
    <Box width="100%" zIndex="1" transform="translateY(200)">
      <div className="slide">
        <Box backgroundImage={`url(${SLIDE_1})`} />
        <Box backgroundImage={`url(${SLIDE_2})`} />
        <Box backgroundImage={`url(${SLIDE_3})`} />
        <Box backgroundImage={`url(${SLIDE_4})`} />
        <Box backgroundImage={`url(${SLIDE_5})`} />
      </div>
      <Wave isWaveWelcome height="40px" transform="translateY(-38px)" />
    </Box>
  );
}

export default ImagesFade;
