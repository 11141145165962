import React from 'react';
import { Box, Text, Button, Center, useDisclosure, Image } from '@chakra-ui/react';

import ImgLogo from '@/assets/icons/logo.png';
import WithAnimation from '@/components/Common/WithAnimation';

import { BG_START_JOURNEY } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import { styles } from './styles';
import Content from './Content';
import txtWording from './locales';


function LoveStory({...rest}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgPrimary" paddingTop="24px" {...rest}>
      {/* Image Cover */}
      <Box padding="16px 32px" paddingBottom="42px">
        <Box css={styles}>
          <WithAnimation>
            <Image src={BG_START_JOURNEY} width="100%" boxShadow="2xl" />
          </WithAnimation>
        </Box>
        <WithAnimation>
          <Box padding="0 8px">
            <Box
              bgColor="bgAlternative"
              padding="16px"
              borderRadius="0 0 24px 24px"
              marginTop="-24px"
            >
              <Box height="24px" />
              <Center>
                <Image src={ImgLogo} width="80px" />
              </Center>
              <WithAnimation>
                <Text padding="8px" color="secondaryColorText" textAlign="center">
                  {txtWording.desc[lang]}
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Center margin="16px 0">
                  <Button
                    colorScheme="whiteAlpha"
                    bgColor="bgSecondary"
                    color="secondaryColorText"
                    fontFamily="body"
                    size="sm"
                    fontWeight="light"
                    onClick={() => onOpen()}
                  >
                    {txtWording.textButton[lang]}
                  </Button>
                </Center>
              </WithAnimation>
            </Box>
          </Box>
        </WithAnimation>
      </Box>

      <Content onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}

export default LoveStory;
