import React from 'react';

import { Box, Text, Center } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';
import ImagesFade from '@components/Common/ImagesFade';
import ImgLogo from '@/assets/icons/Monogram White.png';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';

/**
 * function to render ayat section with Ayat content
 * @returns {JSX.Element}
 */
function AyatSection() {
  return (
    <Box>
      <ImagesFade />
      <Box padding="42px 32px 42px 32px " bgColor="bgPrimary" marginTop="-40px">
        <Box p="0px 32px 24px 32px" bg="bgAlternative" borderRadius="16px">
          <Center>
            <Image src={ImgLogo} maxWidth="140px" />
          </Center>
          <WithAnimation>
            <Text textAlign="center" fontSize="sm" color="secondaryColorText" paddingTop="16px">
              {txtWording.ayat[lang]}
              <br />
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Text
              fontSize="sm"
              fontWeight="bold"
              align="center"
              color="secondaryColorText"
              marginTop="8px"
            >
              {txtWording.ayatName[lang]}
            </Text>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(AyatSection);
