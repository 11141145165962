import React from 'react';
import { Box } from '@chakra-ui/react';

import RSVP from '@components/Section/RSVP';
import useLang from '@hooks/useLang';

import { BG_RSVP } from '@/constants/assets';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

import * as ID from '@/constants/identifier';

function RSVPNonCovidSection({ ...rest }) {
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary" padding="32px 16px" {...rest}>
      <Box
        bgColor="mainColor"
        marginBottom="0px"
        borderRadius="16px"
        bgImage={`url(${BG_RSVP})`}
        bgSize="cover"
        bgPosition="center"
      >
        <Box bgColor={BG_SECONDARY_TRANSPARENT} padding="16px 32px" borderRadius="16px">
          <Box transform="translateY(140px)">
            <RSVP lang={lang} name={ID.ID_RSVP_SECTION} />
          </Box>
        </Box>
      </Box>
      <Box height="110px" />
    </Box>
  );
}

export default React.memo(RSVPNonCovidSection);
