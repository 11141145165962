import React from 'react';
import { string, oneOfType, object } from 'prop-types';
import { Box, Text, Heading } from '@chakra-ui/react';

function WeddingDetail({ title, titleProps, subTitle, location, locationRoad }) {
  return (
    <Box m="4" marginBottom="24px">
      <Heading
        fontSize="4xl"
        fontWeight="normal"
        textAlign="center"
        color="mainColorText"
        {...titleProps}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Text fontSize="md" color="black" align="center" paddingTop="16px">
        {subTitle}
      </Text>
      {location && (
        <Text
          marginTop="16px"
          fontSize="md"
          color="black"
          align="center"
          fontWeight="500"
          dangerouslySetInnerHTML={{ __html: location }}
        />
      )}
      {locationRoad && (
        <Text
          fontSize="sm"
          color="black"
          align="center"
          dangerouslySetInnerHTML={{ __html: locationRoad }}
        />
      )}
    </Box>
  );
}

WeddingDetail.propTypes = {
  imgSrc: string,
  location: string,
  locationRoad: string,
  title: string.isRequired,
  titleProps: object,
  subTitle: oneOfType([string, object]),
};

WeddingDetail.defaultProps = {
  imgSrc: '',
  location: '',
  locationRoad: '',
  titleProps: {},
};

export default React.memo(WeddingDetail);
